<template>
  <div class="error">
    <van-empty image="error" description="404错误，找不到该页面" />
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
